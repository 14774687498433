import React, { Component } from "react"
import { navigate } from "gatsby"
import { withFirebase } from "./Firebase"
import _ from "lodash"
import * as ROUTES from "../constants/routes"
import { Modal } from "semantic-ui-react"
import { sendEmail } from "../functions/email"

function getAverage(nums) {
  return nums.reduce((a, b) => a + b) / nums.length
}

class Form extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
    this.state = {
      active: 0,
      modal: false,
      loading: false,
      load: 0,
      submission: [
        {
          title: "Our Rationale",
          description: "Why is international trade important to us?",
          questions: [
            {
              question:
                "Our mission calls on us to provide help to all who need it",
            },
            {
              question:
                "The Board are supportive of wider commercial development",
            },
            {
              question:
                "We have a well-understood financial plan to develop wider commercial income",
            },
            {
              question:
                "We have carried out an audit of our organisation to identify any international relationships within the organisation",
            },
            {
              question:
                "We have carried out an audit of our organisation to identify what international work (commercial or philanthropic) is already happening",
            },
            {
              question:
                "We have carried out an audit of our organisation to identify any partnerships that could be leveraged overseas",
            },
            {
              question:
                "We have considered the benefits of international work for our internal stakeholders",
            },
            {
              question:
                "We have considered the benefits of international work for our external stakeholders",
            },
            {
              question:
                "We want to expand the positive reputation of our organisation and the wider NHS",
            },
            {
              question:
                "We have considered the wider benefits that a more diverse customer group will provide, in relation to our organisation’s resilience.",
            },
            {
              question:
                "We have documented our rationale for international work/ ventures",
            },
            {
              question:
                "Our rationale for international is widely communicated across the organisation ",
            },
          ],
        },
        {
          title: "Our Proposition",
          description:
            "What do we have to offer? How well developed and communicated is our offer?",
          questions: [
            {
              question:
                "We have identified clear areas of strength around which our international proposition will be focused ",
            },
            {
              question:
                "We have a fully developed understanding of the features and benefits our proposition can offer internationally",
            },
            {
              question:
                "We understand the relative strengths and weaknesses of competitive propositions",
            },
            {
              question: "We have documented our proposition",
            },
            {
              question:
                "We have tested our proposition against the expectations and value preferences of potential customers overseas.",
            },
            {
              question: "We have a costing and pricing strategy",
            },
            {
              question: "We have costing and pricing models",
            },
            {
              question:
                "We have international Intellectual property protection in place",
            },
            {
              question:
                "We communicate our proposition effectively to overseas markets via our Website",
            },
            {
              question:
                "We communicate our proposition effectively to overseas markets through emails and correspondence",
            },
            {
              question:
                "We communicate our proposition effectively to overseas markets through social media",
            },
            {
              question:
                "We communicate our proposition effectively to overseas markets in our presentations",
            },
            {
              question:
                "Our communications give some consideration to cultural and linguistic differences?",
            },
          ],
        },
        {
          title: "Our Target Overseas Market(s)",
          description:
            "Which overseas market(s) offer us the best opportunities?",
          questions: [
            {
              question:
                "We have identified a range of potential overseas markets",
            },
            {
              question:
                "We have researched markets in line with our international proposition ",
            },
            {
              question: "We have selected priority overseas markets ",
            },
            {
              question:
                "Our selection is based on an analysis of market potential and our ability to access the market, taking account of PESTLE & SWOT:",
              bullets: [
                "Political factors",
                "Economic factors",
                "Societal factors",
                "Technological factors",
                "Legal factors",
                "Environmental factors",
                "Our strengths",
                "Our weaknesses",
                "The opportunity",
                "Any threats",
              ],
            },
            {
              question:
                "We have documented our approach to market prioritisation",
            },
            {
              question:
                "We have visited our priority markets with a structured investigation/ scoping",
            },
            {
              question:
                "We are active and developing satisfactorily in our selected priority overseas markets ",
            },
          ],
        },
        {
          title: "Our Resources",
          description:
            "How well equipped are we to generate business and deliver to customers?",
          questions: [
            {
              question:
                "We have identified an Executive lead for international",
            },
            {
              question: "We have identified a Clinical lead for international",
            },
            {
              question:
                "The commitment of our organisation is aligned with our aspirations for international trade",
            },
            {
              question:
                "The Board has an effective written strategic commitment to international trade ",
            },
            {
              question:
                "We have a commercial resource with the time and focus to take our international trade forward ",
            },
            {
              question: "They have all the skills they need ",
            },
            {
              question:
                "They have an agreed budget to develop international trade ",
            },
            {
              question:
                "We have identified a specific finance resource available to assist international development",
            },
            {
              question:
                "We have identified resources with the availability to deliver our product/service (Have a resource plan for each of the propositions)",
            },
            {
              question: "We have documented our resource strategy  ",
            },
          ],
        },
        {
          title: "Our Route to Market/Business Model",
          description:
            "Do we have a business model which is sustainable and profitable? Are we happy to enter a market solo or will we need partners?",
          questions: [
            {
              question: "We understand the strategic options available to us  ",
            },
            {
              question:
                "We have evaluated these options against the risk profile of the organisation ",
            },
            {
              question:
                "We have agreed a clear route to market/business model  ",
            },
            {
              question:
                "Our business model takes into account the following variables",
              bullets: [
                "Skills and knowledge, including market knowledge",
                "Finance",
                "Capacity",
                "Understanding who our customer is, and what they want",
                "Understanding our customer’s knowledge of us, and of our offer",
                "Understanding how we will be reimbursed",
                "Consideration of how we will create a saleable proposition",
                "Consideration of how we will create a scaleable proposition",
              ],
            },
            {
              question:
                "Our business model takes account of market preferences, including the following",
              bullets: [
                "Regulatory requirements",
                "Fiscal issues",
                "Cultural expectation",
                "Ethical considerations",
                "Ease of doing business",
              ],
            },
            {
              question:
                "We haWe have documented our route to market/business modelve documented our route to market/business model",
            },
          ],
        },
        {
          title: "Our Partner Strategy",
          description:
            "How well do we understand the characteristics we are looking for in a partner and how we will manage the relationship? ",
          questions: [
            {
              question:
                "We have identified partnerships (UK or overseas-based) as an element in our strategy  ",
            },
            {
              question: "We understand how to identify potential partners  ",
            },
            {
              question:
                "We have a process for assessing capacity, capability and commitment  ",
            },
            {
              question:
                "We understand the legalities of partnership contracts  ",
            },
            {
              question: "We are effective at managing partnerships  ",
            },
            {
              question:
                "We have documented our approach to partner selection  ",
            },
          ],
        },
      ],
    }

    this.setAnswer = this.setAnswer.bind(this)
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  setAnswer = (section, question, answer) => {
    let submission = this.state.submission

    submission[section].questions[question].answer = answer
    submission[section].questions[question].error = false

    //
    this.setState({
      submission: submission,
    })
  }

  addComment = (e, id) => {
    let submission = this.state.submission

    submission[id].comment = e.target.value

    this.setState({
      submission: submission,
    })
  }

  formSubmit = (e, authUser) => {
    e.preventDefault()
    let submission = this.state.submission
    let errors = false

    for (let i in submission) {
      let questions = submission[i].questions
      for (let e in questions) {
        let answer = questions[e].answer

        if (!answer) {
          questions[e].error = true
          errors = true
        }
      }
    }

    this.setState({
      submission: submission,
      modal: errors,
    })

    if (!errors) {
      this.loadResults(authUser)
    }
  }

  loadResults = authUser => {
    let i = this.state.load

    this.setState({
      loading: true,
    })

    if (i > 99) {
      setTimeout(
        function () {
          this.setState(
            {
              completed: true,
            },
            () => {
              let averages = []

              for (let i in this.state.submission) {
                let answers = []
                let questions = this.state.submission[i].questions

                for (let e in questions) {
                  answers.push(questions[e].answer)
                }

                averages.push(getAverage(answers))
              }

              let emailInfo = {
                name:
                  this.props.user.firstName + " " + this.props.user.lastName,
                email: this.props.user.email,
                scores: averages,
              }

              sendEmail(emailInfo)

              this.props.firebase
                .user(authUser.uid)
                .update(this.state, function (error) {
                  if (!error) {
                    navigate(ROUTES.RESULTS)
                  } else {
                    console.log("error")
                  }
                })
            }
          )
        }.bind(this),
        400
      )
    } else {
      setTimeout(
        function () {
          i++
          this.setState({ load: i })
          if (i < 101) {
            //  if the counter < 100, call the loop function
            this.loadResults(authUser)
          } //  ..  setTimeout()
        }.bind(this),
        50
      )
    }
  }

  sectionSwitch = (e, id) => {
    e.preventDefault()

    let newId = id - 1

    if (id === 0) {
      newId = 0
    }

    let submission = this.state.submission
    let errors = false
    let questions = submission[newId].questions

    for (let e in questions) {
      let answer = questions[e].answer

      if (!answer) {
        questions[e].error = true
        errors = true
      }
    }

    this.setState({
      submission: submission,
      modal: errors,
    })

    if (!errors) {
      this.setState({
        active: id,
      })
    }
    this.myRef.current.scrollIntoView()
  }

  toggleModal = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  componentDidMount = () => {
    console.log("mounted")

    console.log(this.props.user)
  }

  render() {
    let questions = this.state.submission
    let active = this.state.active
    const authUser = this.props.user

    if (authUser.completed) navigate(ROUTES.RESULTS)

    return (
      <>
        <Modal
          open={this.state.modal}
          onClose={this.toggleModal}
          onOpen={this.toggleModal}
          size="mini"
          className="modal"
        >
          <div>
            <p className="body--m">
              Please check you have answered all the questions to continue.
            </p>
            <button className="btn" onClick={this.toggleModal}>
              <span>ok</span>
            </button>
          </div>
        </Modal>
        <section className="page_header">
          <div className="wrapper">
            <div className="row">
              <div className="col col-8">
                <h1 className="heading--l">Welcome {authUser.firstName}</h1>
                <p className="heading--m bold">
                  How ready are we for proactive international trade?
                </p>
                <p className="body--m">
                  This exercise will provide a view on where your organisations
                  strengths lie, and how you may benefit from focus in certain
                  areas to increase the saleability, scaleability and success
                  for your international journey.
                </p>
                <p className="body--m">
                  This is a subjective exercise that highlights your specific
                  knowledge (please do not worry if some scores seem low, this
                  will identify the knowledge sharing areas we need to focus on
                  in the workshop). We will use the output of this diagnostic to
                  come together with colleagues from across your organisation to
                  pool your knowledge.
                </p>
                <p className="body--m">
                  When considering each statement, firstly, think about any
                  international specific activity that has been carried out, if
                  none, then have you something in place for domestic work which
                  could potentially be adapted for international activities?
                </p>
                <p className="body--m">
                  Score each statement from 1-10 - with 1 being nothing ready
                  yet and 10 being the most ready for international ventures.
                </p>
              </div>
            </div>
          </div>
        </section>
        {!this.state.loading ? (
          <section className="page_content" ref={this.myRef}>
            <div className="wrapper">
              <div className="row centered">
                <div className="col col-10">
                  <p className="form_section_number body--m">
                    Section {active + 1} of {questions.length}
                  </p>
                  <div className="form_section_counter">
                    {questions.map((section, section_id) => {
                      return (
                        <div
                          className={section_id <= active ? "active" : ""}
                          key={section_id}
                        ></div>
                      )
                    })}
                  </div>
                  <form>
                    {questions.map((section, section_id) => {
                      return (
                        <div
                          className="form_section"
                          key={section_id}
                          style={{
                            display: section_id === active ? "block" : "none",
                          }}
                        >
                          <h3 className="heading--s bold">
                            {section_id + 1 + ". " + section.title}
                          </h3>
                          <p className="heading--m bold">
                            {section.description}
                          </p>
                          {section.questions.map((question, question_id) => {
                            return (
                              <div
                                key={question_id}
                                className={
                                  question.error
                                    ? "input-field error"
                                    : "input-field"
                                }
                              >
                                <label htmlFor={question.question}>
                                  {question.group && (
                                    <span className="group">
                                      {question.group}
                                    </span>
                                  )}
                                  {question.bullets ? (
                                    <>
                                      {question.question}
                                      <ul>
                                        {question.bullets.map(
                                          (bullet, bullet_id) => {
                                            return (
                                              <li key={bullet_id}>{bullet}</li>
                                            )
                                          }
                                        )}
                                      </ul>
                                      <p className="body--s bullets_note">
                                        Score out of 10 for the one statement
                                        including the bullets
                                      </p>
                                    </>
                                  ) : (
                                    question.question + " - Score out of 10"
                                  )}
                                </label>
                                <div className="buttons">
                                  {_.times(10, button_id => {
                                    let active = ""

                                    active =
                                      this.state.submission[section_id]
                                        .questions[question_id].answer ===
                                      button_id + 1
                                        ? "active"
                                        : ""

                                    return (
                                      <button
                                        key={button_id}
                                        type="button"
                                        onClick={() => {
                                          this.setAnswer(
                                            section_id,
                                            question_id,
                                            button_id + 1
                                          )
                                        }}
                                        className={active}
                                      >
                                        {button_id + 1}
                                      </button>
                                    )
                                  })}
                                </div>
                                <div className="scale">
                                  <p>Low</p>
                                  <p>High</p>
                                </div>
                              </div>
                            )
                          })}
                          <div className="input-field">
                            <label htmlFor="comments">Comments/thoughts:</label>
                            <textarea
                              name="comments"
                              onChange={e => this.addComment(e, section_id)}
                            ></textarea>
                          </div>
                          <div className="input-field section_switch">
                            {section_id !== 0 && (
                              <button
                                className="btn grey"
                                onClick={e =>
                                  this.sectionSwitch(e, section_id - 1)
                                }
                                data-id={section_id}
                              >
                                <span>Previous Section</span>
                              </button>
                            )}

                            {section_id <= questions.length - 2 && (
                              <button
                                className="btn blue next"
                                onClick={e =>
                                  this.sectionSwitch(e, section_id + 1)
                                }
                              >
                                <span>Next Section</span>
                              </button>
                            )}
                            {section_id == questions.length - 1 && (
                              <button
                                className="btn next"
                                onClick={e => this.formSubmit(e, authUser)}
                              >
                                <span>Submit</span>
                              </button>
                            )}
                          </div>
                        </div>
                      )
                    })}
                    <div className="input-field"></div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="loading_screen">
            <div className="wrapper">
              <div className="row centered">
                <div className="col-6">
                  <h3 className="heading--l">
                    Please wait while we calculate your results.
                  </h3>
                  <div className="loader">
                    <div
                      className="loader_inner"
                      style={{ width: this.state.load + "%" }}
                    ></div>
                    <span>{this.state.load}%</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </>
    )
  }
}

export default withFirebase(Form)
