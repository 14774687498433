const mandrill = require("mandrill-api/mandrill")
const mandrill_client = new mandrill.Mandrill("JYhXDuzFJEPIoJwGgoXf2w")

export const sendEmail = async content => {
  console.log("email", content)

  let message = {
    subject: "Readiness Results",
    from_email: "create@studiomade.co",
    from_name: "Export Catalyst",
    to: [
      {
        email: content.email,
        type: "to",
      },
    ],
    merge_language: "handlebars",
    html:
      "<p>Hi " +
      content.name +
      "</p>" +
      "<p>Thank you for completing the Readiness questionnaire,, below are your average scores and your full scores can be seen by logging in at <a href='https://readiness.exportcatalyst.com'>https://readiness.exportcatalyst.com</a>.</p>" +
      "<ul>" +
      "<li>Our Rationale: <span>" +
      content.scores[0].toFixed(0) +
      "</span></li>" +
      "<li>Our Proposition: <span>" +
      content.scores[1].toFixed(0) +
      "</span></li>" +
      "<li>Our Target Overseas Market(s): <span>" +
      content.scores[2].toFixed(0) +
      "</span></li>" +
      "<li>Our Resources: <span>" +
      content.scores[3].toFixed(0) +
      "</span></li>" +
      "<li>Our Route to Market/Business Model: <span>" +
      content.scores[4].toFixed(0) +
      "</span></li>" +
      "<li>Our Partner Strategy: <span>" +
      content.scores[5].toFixed(0) +
      "</span></li>" +
      "</ul>" +
      "<p>To explore our full range of services to help you improve your score please go to <a href='https://exportcatalyst.com'>exportcatalyst.com</a></p>",
  }

  return new Promise((resolve, reject) => {
    mandrill_client.messages.send({ message: message }, function (result) {})
  })
}
