import React from "react"
import { compose } from "recompose"
import Layout from "../components/layout"
import Form from "../components/form"
import { AuthUserContext } from "../components/Session"
import { withAuthorization } from "../components/Session"

const condition = authUser => !!authUser

const HomePageBase = () => (
  <AuthUserContext.Consumer>
    {authUser => {
      return <Form user={authUser} />
    }}
  </AuthUserContext.Consumer>
)

const HomePage = compose(withAuthorization(condition))(HomePageBase)

export default () => (
  <Layout activePage="quesitonnaire">
    <HomePage />
  </Layout>
)
